<template>
  <div id="Mask"></div>
  <FrameBox></FrameBox>
  <BackgroundAnimation></BackgroundAnimation>
  <SiteHeader></SiteHeader>
  <div id="Content">
    <router-view></router-view>
  </div>

</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import FrameBox from './components/FrameBox.vue'
import BackgroundAnimation from './components/BackgroundAnimation.vue'

export default {
  name: 'App',
  components: {
    FrameBox,
    BackgroundAnimation,
    SiteHeader,
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

a,
.router-link-exact-active,
.router-link-active {
  text-decoration: none;
  color: inherit;
}

.page_content {
  font-weight: 500;
  font-size: min(3.2vw, 26px);
  -webkit-font-smoothing: antialiased;
}

.text-btn {
  color: #2c3e50;
  transition: color .4s ease;
}

.text-btn:hover {
  color: rgb(180, 180, 180);
}

</style>
