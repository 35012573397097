<template>
  <div id="three-js-container">
  </div>
</template>

<script setup>
import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import discTexture from '@/assets/textures/disc.png';

let renderer, scene, camera;
let particles;
const PARTICLE_SIZE = 7;
let raycaster;
let pointer;

onMounted(() => {
  init();
  animate();
});

function init() {
  const container = document.getElementById('three-js-container');

  scene = new THREE.Scene();
  renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  renderer.setSize(container.clientWidth, container.clientHeight);
  renderer.setClearColor(0xffffff, 1);
  container.appendChild(renderer.domElement);

  camera = new THREE.PerspectiveCamera(45, container.clientWidth / container.clientHeight, 1, 10000);
  camera.position.z = 250;

  let boxGeometry = new THREE.BoxGeometry(200, 200, 200, 32, 32, 32);
  boxGeometry.deleteAttribute('normal');
  boxGeometry.deleteAttribute('uv');
  boxGeometry = BufferGeometryUtils.mergeVertices(boxGeometry);

  const positionAttribute = boxGeometry.getAttribute('position');

  const colors = [];
  const sizes = [];

  const color = new THREE.Color();

  for (let i = 0, l = positionAttribute.count; i < l; i++) {
    color.setHSL(0.01 + 0.1 * (i / l), 1.0, 0.5);
    color.toArray(colors, i * 3);
    sizes[i] = PARTICLE_SIZE * 0.5;
  }

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', positionAttribute);
  geometry.setAttribute('customColor', new THREE.Float32BufferAttribute(colors, 3));
  geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1));

  const material = new THREE.ShaderMaterial({
    uniforms: {
      color: { value: new THREE.Color(0x808080) },
      pointTexture: { value: new THREE.TextureLoader().load(discTexture) },
      alphaTest: { value: 0.9 }
    },
    vertexShader: vertexshader,
    fragmentShader: fragmentshader
  });

  particles = new THREE.Points(geometry, material);
  particles.position.x -= 100;
  scene.add(particles);

  raycaster = new THREE.Raycaster();
  pointer = new THREE.Vector2();

  window.addEventListener('resize', onWindowResize);
}

function onWindowResize() {
  const container = document.getElementById('three-js-container');
  camera.aspect = container.clientWidth / container.clientHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(container.clientWidth, container.clientHeight);
}

function animate() {
  requestAnimationFrame(animate);
  render();
}

function render() {
  particles.rotation.x += 0.00025;
  particles.rotation.y += 0.0005;

  raycaster.setFromCamera(pointer, camera);

  renderer.render(scene, camera);
}

const vertexshader = `
attribute float size;
attribute vec3 customColor;

varying vec3 vColor;

void main() {
  vColor = customColor;

  vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );

  gl_PointSize = size * ( 300.0 / -mvPosition.z );

  gl_Position = projectionMatrix * mvPosition;
}
`;

const fragmentshader = `
uniform vec3 color;
uniform sampler2D pointTexture;
uniform float alphaTest;

varying vec3 vColor;

void main() {
  gl_FragColor = vec4( color * vColor, 1.0 );

  gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );

  if ( gl_FragColor.a < alphaTest ) discard;
}
`;

import { onMounted } from 'vue';
</script>

<style scoped>
#three-js-container {
  position: fixed;
  z-index: 0;
  left: max(20px, 4vmin);
  right: max(20px, 4vmin);
  top: max(20px, 4vmin);
  bottom: max(20px, 4vmin);
  pointer-events: none;
  opacity: 0.2
}
</style>