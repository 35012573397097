import { createRouter, createWebHistory } from "vue-router";


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./components/HomePage.vue"),
    },
    {
      path: "/projects",
      name: "projects",
      component: () => import("./components/ProjectsList.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./components/ContactForm.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () => import("./components/AboutPage.vue"),
    },
    {
      path: "/imprint",
      name: "imprint",
      component: () => import("./components/ImprintPage.vue"),
    }

  ],
});

export default router;