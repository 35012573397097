<template>
    <div id="FrameBox" class="frame">
        <div class="frame_line frame_line-left"></div>
        <div class="frame_line frame_line-right"></div>
        <div class="frame_line frame_line-top"></div>
        <div class="frame_line frame_line-bottom"></div>
        <footer>
            <router-link to="/imprint" class="imprint-link text-btn">Imprint</router-link>
        </footer>
    </div>
</template>

<style scoped>
.frame {
    position: fixed;
    z-index: 10;
    left: max(20px, 4vmin);
    right: max(20px, 4vmin);
    top: max(20px, 4vmin);
    bottom: max(20px, 4vmin);
    pointer-events: none;
}

.frame_line-top {
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
}

.frame_line-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
}

.frame_line-bottom {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
}

.frame_line-left {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
}

.frame_line {
    position: absolute;
    background-color: #000000;
    opacity: .5;
}

.imprint-link {
    position: absolute;
    bottom: 0;
    font-size: 15px;
    left: max(20px, 4vmin);
    pointer-events: auto;
    padding-bottom: 10px;
}
</style>