<template>
    <header id="SiteHeader" class="siteHeader">
        <h1 class="siteHeader_title">Cerebral Apps</h1>
        <p class="siteHeader_subtitle">AI and Mixed Reality applications</p>
        <nav class="siteHeader_nav">
            <ol>
                <li><router-link class="text-btn" to="/">Home</router-link></li>
                <li><router-link class="text-btn" to="/projects">Projects</router-link></li>
                <li><router-link class="text-btn" to="/about">About</router-link></li>
                <li><router-link class="text-btn" to="/contact">Contact</router-link></li>                
            </ol>
        </nav>
    </header>
</template>

<style scoped>
.siteHeader {
    position: fixed;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: start;
    left: 91.28px;
}
.siteHeader_subtitle {
    margin-top: 5px;
    font-weight: 400;
}

.siteHeader_title {
    font-size: 30px;
    font-weight: 200;
}

ol {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 20px;
}

li {
    text-align: left;
}
</style>